import request from "@/api/request";

export function getAlarmRule(id) {
    return request({
        url: `/alarmrules/${id}`,
        method: 'get'
    })
}

export function deleteAlarmRule(id) {
    return request({
        url: `/alarmrules/${id}`,
        method: 'delete'
    })
}

export function updateAlarmRule(data) {
    return request({
        url: `/alarmrules`,
        method: 'put',
        data: data
    })
}

export function createAlarmRule(data) {
    return request({
        url: `/alarmrules`,
        method: 'post',
        data: data
    })
}

export function testAlarmRule(data) {
    return request({
        url: `/alarmrules/test`,
        method: 'post',
        data: data
    })
}

